import PropTypes from 'prop-types';
import clsx from 'clsx';

import Style from '../Card/Card.module.scss';

/* Card Title Component
------------------------------------*/ 
function CardTitle({ className, align, children }){
    return(
        <strong 
            role="heading"
            aria-level="2"
            className={clsx(
                Style.title, 
                className, 
                align && Style[`align__${align}`]
            )}
        >
            {children}
        </strong>
    )
}

CardTitle.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    align: PropTypes.oneOf(["left", "center", "right"])
};

export default CardTitle;