/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

export const useWindowDimensions = () => {
    const hasWindow = typeof window !== 'undefined';


    function getWindowDimensions() {
        const width = hasWindow ? window.innerWidth : null;
        const height = hasWindow ? window.innerHeight : null;

        return{
            width,
            height,
            isDesktop: width > 1140,
            isTablet: width >= 768 && width <= 1140,
            isMobile: width <= 768,
            isHandheld: width <= 1140 /* Gets most use case handheld (phone, tablet, phablet) max widths */ 
        }
    }


    const [ windowDimensions, setWindowDimensions ] = useState(getWindowDimensions());


    useEffect(() => {
        if (hasWindow) {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleResize);

            return () => window.removeEventListener('resize', handleResize);
        }

    }, [hasWindow]);

    return windowDimensions;
}