export function IconHotspotHover(props){
    return(
        <svg {...props} width="100%" height="100%" viewBox="0 0 74 74">
            <defs>
                <filter id="Ellipse_23" x="0" y="0" width="74" height="74" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="3" result="blur"/>
                <feFlood floodOpacity="0.161"/>
                <feComposite operator="in" in2="blur"/>
                <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_1056" data-name="Group 1056" transform="translate(-55 -53.001)">
                <g transform="matrix(1, 0, 0, 1, 55, 53)" filter="url(#Ellipse_23)">
                <circle id="Ellipse_23-2" data-name="Ellipse 23" cx="28" cy="28" r="28" transform="translate(9 6)" fill="#4c7b9a"/>
                </g>
                <path id="Path_801" data-name="Path 801" d="M17.208-10.476v-3.312H10.944v-6.876H7.308v6.876H1.044v3.312H7.308v7.128h3.636v-7.128Z" transform="translate(83 100.001)" fill="#dfe9ef"/>
            </g>
        </svg>

    )
}