"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Image from "next/image";
import { blurImage } from 'lib/Constants';
import parseSrcset from 'lib/Utils/parseSrcset';
import { useEffect, useState } from 'react';
import { cloudinaryCmsImageLoader, cloudinaryCms2ImageLoader } from 'thirdparty/Cloudinary/cloudinaryLoader';
//import Link from 'next/link';
import SmartLink from 'components/navigation/SmartLink';

import Style from '../Card/Card.module.scss';
import ContentImage from '../ContentImage';


/* Card Media Component
------------------------------------*/ 
function CardMedia({ 
    className = "", 
    alt = "", 
    // height, 
    src, 
    type = "", 
    image = {}, 
    children, 
    blurUrl, 
    imageLink,
    ...other
    // width,
    // filename
}){
    const [ blurImg, setBlurImg ] = useState(blurImage)

    useEffect(() => {
        if( blurUrl ){
            const img = blurUrl.includes("1w") ? parseSrcset(blurUrl) : blurUrl;
            setBlurImg(img)

        }else{
            setBlurImg(blurImage)
        }

    }, [])


    return(
        <div role="presentation" className={clsx(Style.media, className)}>
            {type === "image" && (
                <>
                    {image?.filename ? (
                        imageLink ? (
                            <SmartLink href={imageLink} className={Style.imageLink}>
                                <Image 
                                    src={image?.filename ?? blurImage} 
                                    alt={alt} 
                                    height={image?.height ?? 100}
                                    width={image?.width ?? 100}
                                    placeholder='blur'
                                    blurDataURL={blurImg}
                                    className={Style.image}
                                    loader={image?.url.includes("/cms/cms") ?  cloudinaryCms2ImageLoader : cloudinaryCmsImageLoader}
                                /> 
                            </SmartLink>
                        ) : (

                            <Image 
                                src={image?.filename ?? blurImage} 
                                alt={alt} 
                                height={image?.height ?? 100}
                                width={image?.width ?? 100}
                                placeholder='blur'
                                blurDataURL={blurImg}
                                className={Style.image}
                                loader={image?.url.includes("/cms/cms") ?  cloudinaryCms2ImageLoader : cloudinaryCmsImageLoader}
                            /> 

                        )
                    ):(
                        imageLink ? (

                            <SmartLink href={imageLink} className={Style.imageLink}>
                                <ContentImage 
                                    url={src ?? blurImage}
                                    alt={alt} 
                                    img={{ 
                                        width: other?.width ?? 100, 
                                        height: other?.height ?? 100 
                                    }}
                                />
                            </SmartLink>

                        ) : (

                            <ContentImage 
                                url={src ?? blurImage}
                                alt={alt} 
                                img={{ 
                                    width: other?.width ?? 100, 
                                    height: other?.height ?? 100 
                                }}
                            />

                        )
                        
                    )}
                </>
            )}
            
            
            {type === "video" && 
                <video controls className={Style.video}>
                    <source src={src} />
                </video>
            }


            {children && (
                <>{children}</>
            )}
        </div>
    )
}

CardMedia.propTypes = {
    className: PropTypes.string,
    children: PropTypes.element,
    type: PropTypes.oneOf(["image", "video", "audio", "iframe"]),
    src: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    image: PropTypes.object,
    height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
};

export default CardMedia