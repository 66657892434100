import { IconChevronLeft } from "components/icons/IconChevronLeft";
import Style from "./PodSlider.module.scss";
import clsx from "clsx";


function LeftButton({
    onClick = () => null, 
    isDisabled = false,
    className 
}){
    return(
        <button 
            type="button" 
            className={clsx(Style.navigateBtn, Style.left, className)}
            onClick={onClick}
            disabled={isDisabled}
        >
            <IconChevronLeft width="30" height="30" />
        </button>
    )
}


export default LeftButton;