"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';


function InfoWindow({ 
    content, 
    marker, 
    shouldOpen, 
    map, 
    shouldFocus = false 
}){
    const [ info, setInfo ] = useState()

    useEffect(() => {
        if( !info && content ){
            setInfo(
                new window.google.maps.InfoWindow({
                    content: content,
                })
            )
        }

    }, [content, info])


    useEffect(() => {
        const options = {
            anchor: marker,
            map,
            shouldFocus: shouldFocus
        };


        if( info && marker && !shouldOpen ){
            marker.addListener("click", () => {
                info.open(options)
            })

        }else if( info && marker && shouldOpen ){
            info.open(options)
        }  

    }, [map, marker, info])
   
    
    return null;
}


InfoWindow.propTypes = {
    content: PropTypes.string,
    anchor: PropTypes.object,
    shouldOpen: PropTypes.bool,
    map: PropTypes.object,
    shouldFocus: PropTypes.bool
}


export default InfoWindow;