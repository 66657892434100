import clsx from 'clsx';
import PropTypes from 'prop-types';
import utils from 'styles/globals/utils.module.scss';

/**
 * CMS Content Block
 * @returns Heading
 */

function RenderHeading({ 
    headingTitle = null, 
    headingType = null, 
    headingStyle = null, // if you want to no use the bootstrap heading style
    useHeadingTypeStyle = true,
    position = null, 
    className = null,
    color = null,
    children = null,
    element = "span",
    backgroundColor = null,
    tradeStatus = null,
    customer_group_id
}){
    const Element = element === null ? "p" : element;

    const headingClasses = clsx( 
        ((headingStyle || headingType) && useHeadingTypeStyle) && utils[`fs_${headingStyle || headingType}`], 
        position && utils[`text_${position}`],
        color && utils[`text_${color?.class ?? color}`],
        backgroundColor && (utils[`bg_${backgroundColor?.class}`], utils.py_2),
        className,
    );

    if ((!headingTitle && !children) || customer_group_id === 1 && tradeStatus === 'no') {
        return null;
    } 

    return(
        <Element className={headingClasses}>
            {children ?? headingTitle}
        </Element>
    )
}


RenderHeading.propTypes = {
    className: PropTypes.string,
    headingTitle: PropTypes.string,
    headingType: PropTypes.string,
    headingClass: PropTypes.string,
    headingStyle: PropTypes.string,
    position: PropTypes.string,
    color: PropTypes.string,
    element: PropTypes.string,
    backgroundColor: PropTypes.object,
    tradeStatus: PropTypes.string,
    customer_group_id: PropTypes.number,
    useHeadingTypeStyle: PropTypes.bool,
}


export default RenderHeading;