import Style from './RenderCTA.module.scss'
import utils from 'styles/globals/utils.module.scss';
import clsx from 'clsx';
import Button from 'components/forms/Button';
import SmartLink from 'components/navigation/SmartLink';
import PropTypes from "prop-types";

function RenderCTA({
    position, 
    ctaType = "default",
    ctaText,
    ctaUrl = "#",
    onClick,
    backgroundColor,
    marginTop = 0,
    marginBottom = 0,
    tradeStatus,
    customer_group_id
}) {

    if (customer_group_id === 1 && tradeStatus === 'no') {
        return null;
    }

    const handleClick = (e) => {
        if (onClick) {
            e.preventDefault();
            onClick();
        }
    };

    return (
        <p 
            className={clsx(
                Style.block, 
                utils[`justify_content_${position}`],
                marginTop && utils[`mt_${marginTop}`],
                marginBottom && utils[`mb_${marginBottom}`]
            )}>

            {ctaType === "button" ? (
                <Button
                    type="link"
                    href={ctaUrl ?? "#"} 
                    color={backgroundColor ?? "ocean"}
                    onClick={onClick ? handleClick : null}
                >
                    {ctaText}
                </Button>
            ) : (
                <SmartLink
                    href={ctaUrl ?? "#"}
                    className={utils[ctaType]}
                    title={`learn more on ${ctaText} page`}
                    onClick={onClick ? handleClick : null}
                >
                    {ctaText}
                </SmartLink>
            )}
        </p>
    );
}

RenderCTA.propTypes = {
    ctaType: PropTypes.oneOf(["default", "button", "cta_2", "cta_3"]),
    onClick: PropTypes.func  
};

export default RenderCTA;