"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import clsx from "clsx"
import SliderPod from "components/product/SliderPods/SliderPod";
import PodSlider from "components/product/PodSlider";
import { useNostoRecommendations } from "./hooks/useNostoRecommendations";
import { useMemo } from "react";
import Divider from "components/display/Divider";
import RenderHeading from "components/blocks/RenderHeading"
import ErrorBoundary from "services/ErrorBoundary";
import Grid from "components/layout/Grid";
import GridColumn from "components/layout/GridColumn";
import Image from "next/image";
import { cloudinaryCms2ImageLoader, cloudinaryCmsImageLoader } from "thirdparty/Cloudinary/cloudinaryLoader";
import { blurImage } from "lib/Constants";

import utils from 'styles/globals/utils.module.scss';
import Style from './Nosto.module.scss'
import useCurrentSlide from "components/product/PodSlider/hooks/useCurrentSlide";

function NostoPlacement({ 
    id = "", 
    className = "", 
    sliderClassName = "",
    type = "slider", 
    showTopDivider = false, 
    showBottomDivider = false,
    recommendationTitle = null,
    size = 4, 
    showTitle,
    headingType = 2,
    element = "h2",
    position = "center",
    backgroundColor,
    paddingY = 4,
    image = [],
    gridColumnStart = 2,
    gridColumnEnd = 10
}){
    const recommendations = useNostoRecommendations(id)
    const currentSlide = useCurrentSlide()


    const settings = {
        animationEnded(s) {
            currentSlide.setCurrent(s.track.details.rel)
        },
        initial: 0,
        loop: false,
        mode: 'snap',
        spacing: 10,
        breakpoints: {
            '(min-width: 1025px)': {
                slides: {
                    perView: size,
                    spacing: 10,
                }
            },
            '(max-width: 1024px)': {
                slides: {
                    perView: size > 3 ? 3 : size,
                    spacing: 10
                }   
            },
            '(max-width: 850px)': {
                slides: {
                    perView: 2.5,
                    origin: 'center',
                    spacing: 10
                }
            },
            '(max-width: 600px)': {
                slides: {
                    perView: 1.5,
                    origin: 'center',
                    spacing: 20
                }
            }
        }
    }



    const recommended = useMemo(() => {
        if( Array.isArray(recommendations?.primary ) && recommendations?.primary.length ){
            return(
                <>
                    {
                        recommendations?.primary.map((item, index) => {
                            const isLoaded = index < currentSlide.loaded.length + (size - 1)

                            return(
                                <SliderPod 
                                    {...item}
                                    image_url={item?.imageUrl}
                                    key={item?.productId}
                                    linkParams={`nosto=${id}&resultId=${recommendations?.resultId}`} 
                                    isLoaded={isLoaded}
                                />
                            )
                        })
                    }
                </>
            )
        }
        
    }, [ recommendations ])



    return(
        <ErrorBoundary>
            {(showTopDivider && !!recommended) && (
                <Divider className={utils.my_10} />
            )}


            <Grid id={id} className={clsx("nosto_element", className)}> 
                {(!!recommended && type === "slider") && (
                    <>
                        <GridColumn
                            start={gridColumnStart}
                            end={gridColumnEnd}
                            className={clsx( 
                                Style.element,
                                backgroundColor ? utils[`bg_${backgroundColor}`] : utils.bg_mist,
                                !!recommended && [ paddingY && utils[`py_${paddingY}`]  ]
                            )}
                        >
                            <>
                                {recommendationTitle && (
                                    <RenderHeading 
                                        {...{ element, position, headingType }} 
                                        className={utils.fs_2}
                                    >
                                        {recommendationTitle}
                                    </RenderHeading>
                                )}


                                {recommended && (
                                    <PodSlider
                                        className={sliderClassName}
                                        useButtons
                                        settings={settings}
                                        size={size}
                                        {...{
                                            showTitle, 
                                            backgroundColor
                                        }}
                                    >
                                        {recommended}
                                    </PodSlider>
                                )}
                            </>
                        </GridColumn>


                        {Array.isArray(image) && image.length ? (
                            <GridColumn 
                                className={Style.imgCntr}
                                start={gridColumnEnd}
                                end={11}
                            >
                                <Image 
                                    className={Style.image}
                                    width={image[0]?.width}
                                    height={image[0]?.height}
                                    alt={recommendationTitle}
                                    placeholder="blur"
                                    blurDataURL={blurImage}
                                    src={image[0]?.filename}
                                    loader={image[0]?.url.includes("/cms/cms") ?  cloudinaryCms2ImageLoader : cloudinaryCmsImageLoader}
                                />
                            </GridColumn>
                        ):null}
                    </>
                )}
            </Grid>


            {(showBottomDivider && !!recommended) && (
                <Divider className={utils.my_10} />
            )}
        </ErrorBoundary>
    )
}


NostoPlacement.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    showBottomDivider: PropTypes.bool,
    showTopDivider: PropTypes.bool,
    backgroundColor: PropTypes.string
};


export default NostoPlacement;