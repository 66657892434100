
/**
 * CMS based Cloudinary URL parser
 * expected pattern: desktop=<Webp_image_URL>,mobile=<Webp_image_URL> 
 * @param {string} cloudinary_url 
 * @returns object
 */

export default function useCmsCloudinary({ cloudinary_url = "" }){
    const cloudinary = {};
    const split = typeof cloudinary_url === "string" ? cloudinary_url.replace(/\s/g,'').split(",") : null;

    if( Array.isArray(split) ){
        split.forEach(element => {
            const item = element.split("=")

            if( Array.isArray(item) ){
                cloudinary[item[0]] = item[1];
            }
        });
    }
    
    return {
        ...cloudinary
    }
}