import PropTypes from 'prop-types';
import clsx from 'clsx';

import Style from '../Card/Card.module.scss';


/* Card CTAs Component
------------------------------------*/ 
function CardLinks({ className, align, children }){
    return(
        <nav className={clsx(Style.links, className, align && Style[`align__${align}`])}>
            {children}
        </nav>
    )
}

CardLinks.propTypes = {
    className: PropTypes.string,
    align: PropTypes.oneOf(["left", "center", "right", "vertical"])
};

export default CardLinks