/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";

export default function useCurrentSlide(){
    const [ current, setCurrent ] = useState(0);
	const [ loaded, setLoaded ] = useState([])

    useEffect(() => {
		const newLoaded = [ ...loaded ];

		newLoaded[current] = true;
		setLoaded(newLoaded);

	}, [ current ])


    return{
        current,
        loaded,
        setCurrent,
    }

}