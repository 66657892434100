"use client"

import { useState } from 'react';
import PropTypes from 'prop-types';
import Image from "next/image";
import Link from "next/link";
import clsx from 'clsx';


import Style from './RolloverImage.module.scss'


function RolloverImage({
    primarySrc,
    isOverlay,
    secondarySrc,
    href,
    title,
    className,
    width,
    height
}){

    const [src, setSource] = useState(primarySrc);
    const [ showOverlay, setOverlay] = useState(false)

    const onRollOver = (e) => {

        switch(e.type){
            case "mouseenter": 
                (isOverlay ? setOverlay(true) : setSource(secondarySrc) )
                break;

            default:
                (isOverlay ? setOverlay(false) : setSource(primarySrc) )
                return;
        }
        
    }

    const imageContent = (
        <>
            {showOverlay && (
                <div 
                    className={Style.overlay} 
                    style={{
                        backgroundImage: `url(${secondarySrc})`,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundSize: 'cover',
                    }} 
                />
            )}
            <Image 
                src={isOverlay ? primarySrc : src} 
                width={width}
                height={height}
                alt={title}
                className={clsx(showOverlay && Style.darken, Style.image)}
            />
        </>
    );

    return href ? (
        <Link 
            href={href}
            title={title}
            onMouseEnter={onRollOver}
            onMouseLeave={onRollOver}
            className={clsx(Style.block, className)}
        >
            {imageContent}
        </Link>
    ) : (
        <div 
            title={title}
            onMouseEnter={onRollOver}
            onMouseLeave={onRollOver}
            className={clsx(Style.block, className)}
        >
            {imageContent}
        </div>
    );
}


RolloverImage.propTypes = {
    href: PropTypes.string,
    title: PropTypes.string.isRequired,
    primarySrc: PropTypes.string.isRequired,
    secondarySrc: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    isOverlay: PropTypes.bool
}


export default RolloverImage;