"use client"

import React, { useState } from 'react';
import Styles from './imageHotspot.module.scss';
import { IconHotspot } from 'components/icons/IconHotspot';
import { IconHotspotHover } from 'components/icons/IconHotspotHover';
import { useMemo } from 'react';
import ErrorBoundary from 'services/ErrorBoundary';
//import { sendGTMEvent } from '@next/third-parties/google';

// Hotspot Component
const Hotspot = ({ position, hotspotText, onClick, hotspotUrl }) => {
    const [isHovered, setIsHovered] = useState(false);
    
    return (
        <a 
            href={hotspotUrl}
            className={Styles.hotspot}
            style={{ 
                position: 'absolute',
                left: `${position.x * 100}%`,
                top: `${position.y * 100}%`,
                transform: 'translate(-50%, -50%)'
            }} 
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            title={hotspotText}
        >
           <div className={Styles.hotspotWrapper}>
                <IconHotspot />
                <div className={Styles.hotspotText}>
                    {hotspotText}
                </div>
                <div className={Styles.iconHotspotHover}>
                    <IconHotspotHover />
                </div>
            </div>
        </a>
    )
}

    // Hotspots Component
  const Hotspots = ({ hotspots, isLazyLoad }) => {

    const hotspotData = useMemo(() => {
        let data = [];

        if (hotspots) {
            hotspots.forEach((item, key) => {
                const obj = {text:item.hotspotText, url:item.hotspotUrl}
                //ga4Tracker(isLazyLoad ? 'image_hotspots_display' : 'hero_hotspots_display', obj);
            });

            data = hotspots.map(item => ({
                position: { x: item.position.x, y: item.position.y },
                hotspotText: item.hotspotText,
                hotspotUrl: item.hotspotUrl
            }));
        }

        return data

    }, [ hotspots, isLazyLoad ])

    
    return(
        <ErrorBoundary>
            {hotspotData.map((hotspot, index) => (
                <Hotspot key={index} {...hotspot} />
            ))}
        </ErrorBoundary>
    )
  }

  export default Hotspots;