"use client"

import Button from "components/forms/Button";

function GetDirectionsButton(){
    return(
        <Button
            type="button"
            size="full"
            onClick={() => window.open("https://www.google.com/maps/dir//AuthenTEAK,+1094+Huff+Rd+NW,+Atlanta,+GA+30318/@33.7888846,-84.4267802,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x88f504c43b19231f:0x56e4dcc326c9354b!2m2!1d-84.4243093!2d33.7887621!3e0")}
        >
            Get Directions
        </Button>
    )
}

export default GetDirectionsButton;