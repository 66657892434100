"use client"

import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';

import Styles from './Maps.module.scss'


function Maps({ center, zoom, children }){
    const ref = useRef()
    const [ map, setMap ] = useState(null);


    useEffect(() => {
        if (ref.current && !map) {
            setMap(
                new window.google.maps.Map(ref.current, {
                    center,
                    zoom,
                })
            );
        }

    }, [ center, zoom, map ])



    return(
        <>
            <div 
                ref={ref} 
                className={Styles.block}
            />

            {React.Children.map(children, (child) => {
                if( React.isValidElement(child) ){
                    return React.cloneElement(child, { map });
                }
            })}
        </>
    )
}


Maps.propTypes = {
    center: PropTypes.object, 
    zoom: PropTypes.number, 
    children: PropTypes.element 
}


export default Maps;