import clsx from "clsx";
import Style from "./PodSlider.module.scss";


function Dots({ instance, currentSlide }){
    return(
        <aside className={Style.dotBlock}>
            {instance?.track?.details?.slides.length ? (
                <>
                     {[...Array(instance?.track?.details?.slides.length).keys()].map((index) => {
                        return(
                            <button 
                                key={index}
                                onClick={() => instance.moveToIdx(index)}
                                className={clsx(Style.dot, currentSlide === index && Style.dotActive)}
                            />                
                        )    
                    })}
                </>
            ):null}
        </aside>
    )
}

export default Dots;