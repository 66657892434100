/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useMemo, useRef, useState } from "react"
import { NostoContext } from "../context/NostoContext";


export const useNostoRecommendations = (id = "") => {
    const [ nostoState, _ ] = useContext(NostoContext)
    const [ recommendations, setRecommendations ] = useState([])
    const recommRef = useRef(recommendations)



    useMemo(() => {
        if( nostoState?.placements ){
            const slotId = (typeof id === "string") && id.split(",").map(id => id.trim())

            if( Array.isArray(slotId) && slotId.length ){
                slotId.forEach(slot => {
                    if( nostoState?.placements?.[slot] ){
                        recommRef.current = nostoState?.placements?.[slot];
            
                        setRecommendations(
                            nostoState?.placements?.[slot]
                        )
                    }
                });
            }
        }
        
    }, [ nostoState?.placements ] )


    return {
        ...recommendations
    };
};