"use client"

import { Wrapper } from "@googlemaps/react-wrapper";
import { InfoWindow, Maps, Marker } from "thirdparty/Google";
import { showroomLocationMap } from "lib/Constants";

function MapsWrapper(){
    return(
        <Wrapper apiKey={process.env.NEXT_PUBLIC_maps_api_key}>
            <Maps center={showroomLocationMap.center} zoom={showroomLocationMap.zoom}>
                <Marker position={showroomLocationMap.center}>
                    <InfoWindow shouldOpen content={showroomLocationMap.infoWindowContent} />
                </Marker>
            </Maps>
        </Wrapper>
    )
}

export default MapsWrapper;