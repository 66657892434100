/**
 * Parses the AWS srcset from the CMS GraphQL
 * @param {String} srcset 
 * @returns object
 */

export default function parseSrcset( srcset = "" ){
    const obj = {};

    if( !srcset?.includes("http") ){ 
        return obj.w1 = "https://dummyimage.com/1x1/cccccc/777777.jpg";
    }
    
    if( srcset.split(",").length ){
        srcset.split(",").map(item => {
            const img = item.trim().split(" ");
            const key = img[1]?.split("w");
        
            img[1] = "w" + key[0];
    
            obj[`${img[1]}`] = img[0];
        })
    
        return obj;

    }else if( srcset.split(" ").length ){
        srcset.split(" ").map(item => {
            const key = item[1]?.split("w");
        
            item[1] = "w" + key[0];
    
            obj[`${item[1]}`] = item[0];
        })
    
        return obj;
    }
}
