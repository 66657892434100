import PropTypes from 'prop-types';
import Image from "next/image";
import parseSrcset from 'lib/Utils/parseSrcset';
import { blurImage } from 'lib/Constants';
import Picture from '../Picture';
import useCmsCloudinary from 'thirdparty/Cloudinary/useCmsCloudinary';
import { cloudinaryCms2ImageLoader } from 'thirdparty/Cloudinary/cloudinaryLoader'



/**
 * Supports CMS generated AWS Images from a GraphQl response
 * Note: All Next Image Props are valid props
 * 
 * @returns Content Image Component
 */

function ContentImage({ 
    img, 
    title = "authenteak image", 
    height = 100, 
    className, 
    width = 100, 
    url = "",
    isSquare, 
    cloudinary_url = "",
    priority = false,
    ...other 
}){
    const imgSrcset = parseSrcset(img?.srcset);
    const cloudinary = useCmsCloudinary({ cloudinary_url })
    const isHardcodedImage = !img?.filename && !cloudinary_url && url;
    const src = isHardcodedImage ? url : img?.filename;

    return(
        <>
            {cloudinary_url ? (
                <Picture 
                    alt={title} 
                    {...{ className }}
                    {...cloudinary}
                    priority={priority}
                />

            ):(
                <>
                    {img?.url || url ? (
                        <Image 
                            {...other}
                            blurDataURL={imgSrcset?.w1 ?? blurImage}
                            placeholder="blur"
                            src={src}
                            alt={title} 
                            width={img?.width ?? width}
                            height={img?.height ?? height}
                            className={className}
                            priority={priority}
                            loader={isHardcodedImage ? undefined : cloudinaryCms2ImageLoader}
                        />
                    ):null}
                </>
            )}
        </>
    )
}


ContentImage.propTypes = {
    className: PropTypes.string,
    img: PropTypes.object,
    title: PropTypes.string,
    loader: PropTypes.func,
    width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}


export default ContentImage;