"use client"

import Button from 'components/forms/Button';
import { triggerChat } from 'thirdparty/Zendesk/Zendesk.service';


function ChatButton(){
    return(
        <Button 
            type="button" 
            variant="link"
            color="transparent" 
            noAnimate
            onClick={() => triggerChat()}
        >
            Chat Now
        </Button>
    )
}

export default ChatButton;