"use client"

import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';


function Marker({ map, position, children }){
    const [ marker, setMarker ] = useState(null);

    useEffect(() => {
        if( !marker ){
            setMarker(new window.google.maps.Marker())
        }

        return () => {
            if( marker ){
                marker.setMap(null)
            }
        }

    }, [marker])



    useEffect(() => {
        if( marker ){
            marker.setOptions({ map, position })
        }

    }, [ marker, map, position])
    


    return(
        <>
            {React.Children.map(children, (child) => {
                if( React.isValidElement(child) && marker ){
                    return React.cloneElement(child, { map, marker });
                }
            })}
        </>
    );
}


Marker.propTypes = {
    map: PropTypes.object, 
    position: PropTypes.object, 
    children: PropTypes.element
}


export default Marker;